/*=====================
    Address CSS start
==========================*/
.address-list {
    li {
        position: relative;
        display: flex;
        width: 100%;
        margin-top: 20px;

        .address-icon {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0;
            border: 1px solid rgba(0, 0, 0, 0.05);
            box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
            border-radius: 10px;

            &::before {
                content: "";
                position: absolute;
                left: 50px;
                top: 15px;
                width: 18px;
                height: 1px;
                background-color: rgba(var(--light-text), 1);
                z-index: 1;

                [dir="rtl"] & {
                    left: unset;
                    right: 50px;
                }
            }

            &::after {
                content: "";
                position: absolute;
                left: 48px;
                top: 36px;
                width: 20px;
                height: 1px;
                background-color: rgba(var(--light-text), 1);
                z-index: 1;

                [dir="rtl"] & {
                    left: unset;
                    right: 48px;
                }
            }

            span {
                display: flex;
                justify-content: center;
                align-items: center;

                i {
                    font-size: 24px;
                    color: rgba(var(--theme-color), 1);
                }

                &::before {
                    content: "";
                    position: absolute;
                    left: 70px;
                    top: 13px;
                    transform: translate(-50%);
                    width: 5px;
                    height: 5px;
                    background-color: rgba(var(--light-text), 1);
                    border-radius: 100%;
                    z-index: 1;

                    [dir="rtl"] & {
                        left: unset;
                        right: 65px;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    left: 70px;
                    top: 34px;
                    transform: translate(-50%);
                    width: 5px;
                    height: 5px;
                    background-color: rgba(var(--light-text), 1);
                    border-radius: 100%;
                    z-index: 1;

                    [dir="rtl"] & {
                        left: unset;
                        right: 65px;
                    }
                }

                .address-img {
                    stroke: rgba(var(--theme-color), 1);
                    fill: transparent;
                    stroke-width: 1.5px;
                    width: 21px;
                    height: 21px;
                }
            }
        }
    }

    .address-content {
        position: relative;
        overflow: hidden;
        width: calc(100% - 60px);
        margin-left: 10px;
        background: rgba(var(--white), 1);
        padding: 12px 12px 12px 18px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
        border-radius: 10px;

        [dir="rtl"] & {
            margin-left: unset;
            margin-right: 10px;
            padding: 12px 18px 12px 12px;
        }

        .address-checkbox {
            min-height: unset;
            padding-left: 0;
            margin-bottom: 0;
            position: absolute;
            top: 0;
            right: 0;

            [dir="rtl"] & {
                left: 0;
                right: unset;
                margin-left: unset;
                margin-right: 0;
            }

            .form-check-input {
                width: 26px;
                height: 26px;
                border-radius: 0 8px 0;
                margin: 0;
                float: unset;
                background-color: rgba(var(--white), 1);
                border: none;
                background: transparent;

                [dir="rtl"] & {
                    border-radius: 8px 0 8px 0;
                }

                &:active {
                    filter: brightness(100%);
                }

                &:focus {
                    box-shadow: none;
                }
            }

            .form-check-input:checked {
                background-image: url(../images/svg/check.svg);
                background-color: rgba(var(--theme-color), 1);
                box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
                background-size: 16px;
                background-repeat: no-repeat;
                background-position: center;
            }
        }

        .address-place {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h5 {
                color: rgba(var(--dark-text), 1);
                font-weight: 600;
            }

            .edit {
                font-size: 20px;
            }
        }

        p {
            color: rgba(var(--light-text), 1);
            border-top: 1px dashed rgba(var(--dashed-line), 1);
            padding: 8px 0 0 0;
            margin: 8px 0 0 0;
            font-size: 13px;
        }

        .select-option {
            color: rgba(var(--dark-text), 1);
            border-top: 1px dashed rgba(var(--dashed-line), 1);
            font-size: 13px;
            padding: 8px 0 0 0;
            margin: 8px 0 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .form-check {
                display: flex;
                min-height: unset;
                padding-left: 0;
                margin-bottom: 0;
                align-items: center;

                [dir="rtl"] & {
                    padding-left: unset;
                    padding-right: 0;
                }

                .form-check-label {
                    margin-left: 5px;
                    letter-spacing: 0.03em;

                    [dir="rtl"] & {
                        margin-left: unset;
                        margin-right: 5px;
                    }
                }

                .form-check-input {
                    width: 18px;
                    height: 18px;
                    box-shadow: 0px 2px 12px rgb(0 0 0 / 6%);
                    border-radius: 5px;
                    margin: 0;
                }

                .form-check-input:checked {
                    background-image: url(../images/svg/check.svg);
                    background-color: rgba(var(--theme-color), 1);
                    color: rgba(var(--white), 1);
                    border: none;
                    float: unset;
                    margin: 0;
                }
            }
        }
    }
}

.address-filter {
    h3 {
        margin-bottom: 12px;
        color: rgba(var(--dark-text), 1);
    }

    .food-symbol {
        border-bottom: 1px dashed rgba(var(--black), 0.22) !important;
        gap: calc(6px + (12 - 6) * ((100vw - 320px) / (1920 - 320))) !important;

        li {
            border: 1px solid rgba(var(--box-bg), 1);
            border-radius: 7px;

            .food-types {
                h5 {
                    line-height: 1;
                }

                i {
                    line-height: 1;
                }

                &.active {
                    background-color: rgba(var(--theme-color), 1);

                    i {
                        color: rgba(255, 255, 255, 1);
                    }

                    h5 {
                        color: rgba(255, 255, 255, 1);
                    }
                }
            }
        }
    }
}

.address-list {
    margin-top: -10px;
}
