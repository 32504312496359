/*=============================
    order CSS start
===============================*/
.order-tracking {
    background-color: rgba(var(--white), 1);
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    padding: calc(15px + (20 - 15) * ((100vw - 320px) / (1920 - 320)));

    .tracking-place {
        li {
            position: relative;
            display: flex;
            align-items: center;
            gap: 10px;
            padding-bottom: 25px;
            width: 100%;

            &:last-child {
                &::after {
                    display: none;
                }
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 10px;
                left: 70px;
                width: 25px;
                border: 1px dashed rgba(var(--light-text), 1);
                transform: rotate(90deg);

                [dir="rtl"] & {
                    left: unset;
                    right: 70px;
                }
            }

            h6 {
                color: rgba(var(--dark-text), 1);

                &.color-1 {
                    color: rgba(255, 185, 49, 1);
                }

                &.color-2 {
                    color: rgba(68, 111, 221, 1);
                }

                &.color-3 {
                    color: rgba(var(--error-color), 1);
                }

                &.color-4 {
                    color: rgba(var(--success-color), 1);
                }
            }

            span {
                position: relative;
                width: 25px;
                height: 25px;
                background-color: rgba(var(--box-bg), 1);
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;


                &::after {
                    content: "";
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    background-color: rgba(var(--light-text), 1);
                    border-radius: 100%;
                }
            }

            .icon {
                padding: 6px;
                border-radius: 6px;

                &.step-1 {
                    background: rgba(255, 241, 214, 1);
                }

                &.step-2 {
                    background: rgba(225, 232, 250, 1);
                }

                &.step-3 {
                    background: rgba(252, 217, 217, 1);
                }

                &.step-4 {
                    background: rgba(224, 239, 217, 1);
                }
            }

            &.active {
                &::after {
                    border-color: rgba(var(--theme-color), 1);
                }

                span {
                    background-color: rgba(var(--theme-color), 0.15);

                    &::after {
                        background-color: rgba(var(--theme-color), 1);
                    }
                }
            }
        }
    }
}