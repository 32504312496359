/*=====================
    Reset CSS start
==========================*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.custom-container {
  padding: 0 20px;
  background-color: rgba(var(--white), 1);
}

section,
.section-t-space {
  padding-top: 20px;
}

.section-b-space {
  padding-bottom: 20px;
}

.section-lg-t-space {
  padding-top: 50px;
}

.section-lg-b-space {
  padding-bottom: 50px;
}

.panel-space {
  padding-top: 80px !important;
}

.theme-color {
  color: rgba(var(--theme-color), 1);
}

.dark-text {
  color: rgba(var(--dark-text), 1);
}

.light-text {
  color: rgba(var(--light-text), 1);
}

.error-color {
  color: rgba(var(--error-color), 1);
}

.success-color {
  color: rgba(var(--success-color), 1);
}
.rate-color {
  color: rgba(var(--rate-color), 1);
}

.swiper {
  [dir="rtl"] & {
    direction: ltr;
  }
}
