/*=====================
    Title CSS start
==========================*/
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;

  h3 {
    color: rgba(var(--dark-text), 1);
    font-weight: 600;
  }

  a {
    color: rgba(var(--theme-color), 1);
  }
}