/*=============================
    payment CSS start
===============================*/
.payment-list {
    .payment-card-box {
        flex-wrap: wrap;
        padding: 0 15px;
        border-radius: 14px;

        .payment-detail {
            display: flex;
            align-items: center;
            gap: 15px;
            width: 100%;
            padding: 15px 0;

            .add-img {
                width: 35px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;

                .img {
                    width: 35px;
                    height: 30px;
                }

                .add-square-img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 22px;
                    border: 1.5px solid;
                    border-radius: 10px;
                    width: 100%;
                }
            }

            .add-content {
                width: calc(100% - 35px - 15px);
                display: flex;
                justify-content: space-between;
                align-items: center;

                h5 {
                    color: rgba(var(--dark-text), 1);
                }

                .form-check {
                    .form-check-input {
                        box-shadow: none;
                    }

                    .form-check-input:checked {
                        background-color: rgba(var(--theme-color), 1);
                        border-color: rgba(var(--theme-color), 1);
                    }
                }
            }
        }

    }

    .new-card {
        border-top: 1px dashed rgba(var(--dashed-line), 1);
    }
}

.debit-card {
    position: relative;
    background-image: url(../images/background/card.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: auto;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.14);
    border-radius: 14px;

    .card-details {
        padding: 22px;

        .card-name {
            color: rgba(225, 225, 225, 0.5);
        }

        .chip {
            margin-top: 12px;
        }

        .ac-details {
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            li {
                h6 {
                    font-weight: 400;
                    color: rgba(225, 225, 225, 0.5);
                }

                h3 {
                    font-weight: 700;
                    color: rgba(225, 225, 225, 1);
                    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
                }
            }
        }

        .user-name {
            margin-top: 15px;

            h6 {
                font-weight: 400;
                color: rgba(225, 225, 225, 0.5);
            }

            h5 {
                font-weight: 500;
                color: rgba(225, 225, 225, 1);
                letter-spacing: 0.02em;
                text-transform: uppercase;

            }
        }
    }
}

.address-form {
    border-top: 1px dashed rgba(var(--dashed-line), 1);
    padding-top: 20px;
}